import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "nameField",
    "unitField",
    "contentField",
    "outputWrapper",
    "output"
  ]

  initialize() {
    this._updateSuggestion();
  }

  fieldValueChanged() {
    this._updateSuggestion()
  }

  applySuggestion(event) {
    event.preventDefault();

    this.nameFieldTarget.value = this._suggestion();

    // Allow OverrideFieldController to handle override highlighting
    this.nameFieldTarget.dispatchEvent(new Event("input"));

    this._updateSuggestion()
  }

  _updateSuggestion() {
    const suggestion = this._suggestion();

    if(typeof(suggestion) === "string" && suggestion !== this.nameFieldTarget.value) {
      this.outputTarget.text = suggestion;
      this.outputWrapperTarget.classList.remove("d-none");
    } else {
      this.outputWrapperTarget.classList.add("d-none");
    }
  }

  _suggestion() {
    const unit = this.unitFieldTarget.value;
    const content = this.contentFieldTarget.value;

    if (unit.length > 0 && content.length > 0) {
      return `${unit} ${content}`;
    }
  }
}
