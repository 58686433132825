import { Controller } from "@hotwired/stimulus"
import { select2dropdownParent } from "../utils/select2_dropdown_parent"

// https://regex101.com/r/P66tyl/2
const nameOrForAttributesWithIndex = /(safety_data_sheets_attributes\]\[)(\d+)(\]\[[a-z_]+\])/g

// https://regex101.com/r/17V8Sp/1/
const idAttributesWithIndex = /(safety_data_sheets_attributes_)(\d+)(_[a-z_]+)/g

export default class extends Controller {
  static targets = [
    "newItemsList",
    "template",
    "wrapper",
  ]

  initialize() {
   const index = parseInt(this.wrapperTarget.dataset.nextSdsIndex);
   this.nextSdsIndex = index;
  }

  addNew() {
    const newItem = this._prepareNewTemplate(this.nextSdsIndex);
    this.nextSdsIndex++;

    $(this.newItemsListTarget)
      .append(newItem)
      .find("select")
      .select2({
        dropdownParent: select2dropdownParent(),
      });
  }

  _prepareNewTemplate(formIndex) {
    const template = $(this.templateTarget.content).find(".template-wrapper")[0];
    const replaceFormIndex = function(_m, pre, _existingIndex, post) {
      return pre + formIndex + post;
    }

    return template.outerHTML
      .replace(nameOrForAttributesWithIndex, replaceFormIndex)
      .replace(idAttributesWithIndex, replaceFormIndex);
  }
}
