import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "wrapper",
  ]

  initialize() {
    const self = this;

    this.$inputEl = $(this.wrapperTarget).find("input[type=text]:first");
    this.$outputEl = this.$inputEl.closest(".form-group").find(".input-notes");

    this._updateLinks();

    this.$inputEl.on("change", function() {
      self._updateLinks();
    });
  }

  _updateLinks() {
    const hrefs = this.$inputEl.val().split(/\s?,\s?/)
      .map(href => href.trim())
      .filter(href => href.length > 0);

    const links = $(hrefs).map(function(index, href) {
      const el = document.createElement("a");
      el.href = href;
      el.target = "_blank";
      el.text = "Video #" + String(index + 1);
      el.classList.add("btn-link", "btn", "btn-sm", "py-0", "px-1");
      return el;
    });

    this.$outputEl.empty();

    if(links.length === 0) {
      this.$outputEl.addClass("d-none");
    } else {
      this.$outputEl.append(links).removeClass("d-none");
    }
  }
}
