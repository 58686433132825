import { Controller } from "@hotwired/stimulus"
import { select2dropdownParent } from "../utils/select2_dropdown_parent"

// https://regex101.com/r/SoBvyI/3
const nameOrForAttributesWithIndex = /(features_attributes\]\[)(\d+)(\]\[[a-z_]+\])/g

// https://regex101.com/r/vY2cme/1
const idAttributesWithIndex = /(features_attributes_)(\d+)(_[a-z_]+)/g

const select2options = {
  width: "100%",
  tags: true,
  allowClear: true,
};

const ajaxSearchUrl = "/product_variant_features/values";

function ajaxSearchOptions(featureName) {
  return {
    url: ajaxSearchUrl,
    dataType: "json",
    data: function(params) {
      return {
        search: params.term,
        name: featureName,
      }
    }
  }
}

export default class extends Controller {
  static targets = [
    "newFeatureSelect",
    "featureSelect",
    "template",
    "list",
  ]

  initialize() {
    this._initSelect2();
  }

  // Helpers

  _initSelect2() {
    const self = this;

    $(this.featureSelectTargets).each(function(_, el) {
      const $el = $(el);

      const featureName = $el
        .closest(".has-many-item")
        .find(".product-variant-feature-name")
        .val();

      $el.select2({
        dropdownParent: select2dropdownParent(),
        minimumInputLength: 1,
        ajax: ajaxSearchOptions(featureName),
        placeholder: "Wert eingeben",
        ...select2options,
      });
    });

    const $newFeatureSelect = $(this.newFeatureSelectTarget);
    $newFeatureSelect.select2({
      dropdownParent: select2dropdownParent(),
      placeholder: "Merkmal hinzufügen",
      ...select2options
    });

    $newFeatureSelect.on("change", function(event) {
      if(String(event.target.value || "").length === 0) {
        // We're clearing out the previous value
        return;
      }

      self._addNewItem(event.target.value);
      $(event.target).val(null).trigger("change");
    });
  }

  _addNewItem(value) {
    const $listEl = $(this.listTarget);
    const nextIndex = $listEl.find(".has-many-item").length;
    const $newItem = $(this._prepareNewTemplate(nextIndex));
    const $select = $newItem.find("select");

    $select.select2({
      dropdownParent: select2dropdownParent(),
      minimumInputLength: 1,
      ajax: ajaxSearchOptions(value),
      placeholder: "Wert eingeben",
      ...select2options,
    });

    $newItem.find(".product-variant-feature-name").attr("value", value);
    $select.val(null).trigger("change");
    $listEl.append($newItem);
  }

  _prepareNewTemplate(formIndex) {
    const template = $(this.templateTarget.content).find(".has-many-item")[0];
    const replaceFormIndex = function(_m, pre, _existingIndex, post) {
      return pre + formIndex + post;
    }

    return template.outerHTML
      .replace(nameOrForAttributesWithIndex, replaceFormIndex)
      .replace(idAttributesWithIndex, replaceFormIndex);
  }
}
