import { Controller } from "@hotwired/stimulus"

const cssClassDisplayNone = "d-none";
const cssClassOverridenField = "border-warning";
const cssClassOldOriginalField = "border-info";

export default class extends Controller {
  static targets = [
    "originalWrapper",
    "originalField",
    "currentField",
    "currentFieldWrapper",
    "oldOriginalWrapper",
    "oldOriginalField",
    "currentOriginalWrapper",
    "toggleOriginalButton",
    "toggleOriginalWrapper",
  ]

  initialize() {
    this._updateState();

    const self = this;

    $(this.currentFieldTarget).on("select2:select select2:unselect", function() {
      self.fieldValueChanged();
    });
  }

  // Actions

  fieldValueChanged() {
    this._updateState();
  }

  toggleDisplayOriginalButton() {
    this.originalWrapperTarget.classList.toggle(cssClassDisplayNone);
  }

  resetToOriginal() {
    const originalValue = this.originalFieldTarget.value;
    $(this.currentFieldTarget)
      .val(originalValue)
      .trigger("change")
      .trigger("field-was-reset-to-original");
    this.fieldValueChanged();
  }

  resetToOldOriginal() {
    const oldOriginalValue = this.oldOriginalFieldTarget.value
    $(this.currentFieldTarget)
      .val(oldOriginalValue)
      .trigger("change")
      .trigger("field-was-reset-to-original");
    this.fieldValueChanged();
  }

  // Helpers

  _valueOverriden() {
    return this.currentFieldTarget.value !== this.originalFieldTarget.value;
  }

  _hasOldOriginal() {
    return $(this.element).data("hasOldOriginal");
  }

  _visibleSections() {
    var visible = [];
    if(this._valueOverriden()) {
      visible.push("current")
    }
    if(this._hasOldOriginal()) {
      visible.push("old")
    }
    return visible;
  }

  _markAsOverridden(status) {
    var classes = this.currentFieldWrapperTarget.classList;
    if(status) {
      classes.add(cssClassOverridenField);
    }
    else {
      classes.remove(cssClassOverridenField);
    }
  }

  _markAsHasOldOriginal(status) {
    var classes = this.currentFieldWrapperTarget.classList;
    if(status) {
      classes.add(cssClassOldOriginalField);
    }
    else {
      classes.remove(cssClassOldOriginalField);
    }
  }

  _setToggleable(status) {
    var buttonClasses = this.toggleOriginalWrapperTarget.classList;
    if (status) {
      buttonClasses.remove(cssClassDisplayNone);
    } else {
      buttonClasses.add(cssClassDisplayNone);
    }
  }

  _updateState() {
    this._setToggleable(this._valueOverriden() || this._hasOldOriginal());

    if(this._valueOverriden()) {
      this._markAsOverridden(true);
      this.currentOriginalWrapperTarget.classList.remove(cssClassDisplayNone);
    } else {
      this._markAsOverridden(false);
      this.currentOriginalWrapperTarget.classList.add(cssClassDisplayNone);

      if(this._hasOldOriginal()) {
        this._markAsHasOldOriginal(true);
      } else {
        this._markAsHasOldOriginal(false);
      }
    }

    var visibleSections = this._visibleSections();
    if(visibleSections.length > 0) {
      for(const visibleSection of visibleSections) {
        var target = this[visibleSection + 'OriginalWrapperTarget'];
        target.classList.remove("first-visible");
        target.classList.remove("last-visible");
      }
      this[visibleSections[0] + 'OriginalWrapperTarget'].classList.add("first-visible");
      this[visibleSections[visibleSections.length - 1] + 'OriginalWrapperTarget'].classList.add("last-visible");
    }
    else {
      if(!this.originalWrapperTarget.classList.contains(cssClassDisplayNone)) {
        this.toggleDisplayOriginalButton();
      }
    }

  }
}
