import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "wrapper",
    "button",
    "spinner",
    "endMessage",
  ]

  loadMore(event) {
    event.preventDefault();

    event.target.classList.add("d-none");
    this.spinnerTarget.classList.remove("d-none");

    const self = this;

    $.ajax({
      url: event.target.href,
      method: "GET",
    }).done(function(data) {
      self.spinnerTarget.classList.add("d-none");

      if(data !== undefined) {
        $(self.wrapperTarget).append(data);
      } else {
        self.endMessageTarget.classList.remove("d-none");
      }
    });
  }
}
