(function() {
  checkSessionStatus = function() {
    $.ajax({
      url: "/session_check",
      method: "POST",
      cache: false,
      statusCode: {
        410: function() {
          window.location.reload();
        }
      }
    })
  }

  const everyFiveMinutes = 5 * 60 * 1000;
  setInterval(checkSessionStatus, everyFiveMinutes);
})();
