import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "bottomButton",
    "topButton",
  ]

  connect() {
    this._updateButtonVisiblity()

    this._windowResized = this._windowResized.bind(this);
    this._documentScrolled = this._documentScrolled.bind(this);

    $(window).on("resize orientationchange", this._windowResized);
    $(document).on("scroll", this._documentScrolled);
  }

  disconnect() {
    $(window).off("resize orientationchange", this._windowResized);
    $(document).off("scroll", this._documentScrolled);
  }

  scrollToTop(e) {
    e.preventDefault()
    this._scrollTo(0);
  }

  scrollToBottom(e) {
    e.preventDefault()
    this._scrollTo(this._pageHeight() - this._viewportHeight());
  }

  _windowResized() {
    delete this.viewportHeight;
    this._updateButtonVisiblity();
  }

  _documentScrolled() {
    delete this.scrollPosition;
    this._updateButtonVisiblity();
  }

  _updateButtonVisiblity() {
    const isLongPage = this._pageHeight() > this._viewportHeight()*2.5;

    if(!isLongPage) {
      this._setButtonVisiblity(["bottom", "top"], false);
      return;
    }

    this._setButtonVisiblity(["bottom"], this._scrollPosition() < this._pageHeight() - this._viewportHeight()*2);
    this._setButtonVisiblity(["top"], this._scrollPosition() > this._viewportHeight());
  }

  _setButtonVisiblity(buttons, visible) {
    const targets = buttons.map((name) => this[name + "ButtonTarget"]);
    const fn = visible ? "removeClass" : "addClass";
    targets.forEach((target) => $(target)[fn]("faded-out"));
  }

  _scrollTo(y) {
    const animationDuration = parseInt(this.data.get("animation"));
    $([document.documentElement, document.body]).animate({ scrollTop: y }, animationDuration);
  }

  _pageHeight() {
    if(!this.pageHeight) this.pageHeight = this._fetchPageHeight();
    return this.pageHeight;
  }

  _viewportHeight() {
    if(!this.viewportHeight) this.viewportHeight = this._fetchViewportHeight();
    return this.viewportHeight;
  }

  _scrollPosition() {
    if(!this.scrollPosition) this.scrollPosition = this._fetchScrollPosition();
    return this.scrollPosition;
  }

  _fetchPageHeight() {
    // https://stackoverflow.com/a/1147768/10541936
    var body = document.body,
        html = document.documentElement;
    var height = Math.max( body.scrollHeight, body.offsetHeight, 
                           html.clientHeight, html.scrollHeight, html.offsetHeight );
    return height;
  }

  _fetchViewportHeight() {
    var height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return height;
  }

  _fetchScrollPosition() {
    return $(window).scrollTop();
  }
}
