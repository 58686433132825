import $ from "jquery";

$(document).on("turbo:load", () => {
  initListeners();
  $("button.add-product-variant").on("click", () =>
    setTimeout(initListeners, 0)
  );
});

$(document).on("submit", "form.product-form", () => {
  $("input.format-price").each(function () {
    let value = $(this).val();
    value = formatInput(value) * 100;
    $(this).val(value);
  });

  return true;
});

function initListeners() {
  $("input.profit-margin").on("input", function () {
    const parentEl = $(this).parents().eq(3);

    let margin_percentage = $(this).val();
    let purchase_price = parentEl.find("input.purchase-price").val();

    margin_percentage = formatInput(margin_percentage) / 100;
    purchase_price = formatInput(purchase_price);

    let result = purchase_price / (1 - margin_percentage);
    result = parseFloat(result) || 0;

    parentEl.find("input.main-price").val(numberToCurrency(result));
  });

  $("input.main-price").on("input", function () {
    const parentEl = $(this).parents().eq(3);

    let purchase_price = parentEl.find("input.purchase-price").val();
    let main_price = $(this).val();

    purchase_price = formatInput(purchase_price);
    main_price = formatInput(main_price);

    let result = ((main_price - purchase_price) / main_price) * 100;
    result = parseFloat(result) || 0;

    parentEl.find("input.profit-margin").val(numberToCurrency(result));
  });

  $("input.purchase-price").on("input", function () {
    const parentEl = $(this).parents().eq(3);

    let purchase_price = $(this).val();
    let margin_percentage = parentEl.find("input.profit-margin").val();

    margin_percentage = formatInput(margin_percentage) / 100;
    purchase_price = formatInput(purchase_price);

    let result = purchase_price / (1 - margin_percentage);
    result = parseFloat(result) || 0;

    parentEl.find("input.main-price").val(numberToCurrency(result));
  });

  $("input.format-price").on("focusout", function () {
    let data = $(this).val().replaceAll(".", "").replace(",", ".");
    $(this).val(numberToCurrency(data));
  });

  $("input.format-price").on("focus", function () {
    let data = $(this).val();
    data = formatInput(data).replace(".", ",");
    $(this).val(data === "0,00" ? null : data);
  });
}

function formatInput(num) {
  if (num === "") {
    num = 0;
  }

  num = num.toString().replaceAll(".", "").replace(",", ".");
  return parseFloat(num).toFixed(2);
}

function numberToCurrency(number, options) {
  if (number === "") {
    number = 0;
  }

  var options = options || {};
  var precision = options["precision"] || 2;
  var separator = precision > 0 ? options["separator"] || "," : "";
  var delimiter = options["delimiter"] || ".";

  var parts = parseFloat(number).toFixed(precision).split(".");
  return (
    numberWithDelimeter(parts[0], delimiter) + separator + parts[1].toString()
  );
}

function numberWithDelimeter(number, delimiter, separator) {
  try {
    var delimiter = delimiter || ",";
    var separator = separator || ".";

    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + delimiter);
    return parts.join(separator);
  } catch (e) {
    return number;
  }
}
