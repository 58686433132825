import { Controller } from "@hotwired/stimulus"

// https://regex101.com/r/TWlV32/1
const nameOrForAttributesWithIndex = /(\[[a-z_]+\]\[)(\d+)(\]\[[a-z_]+\])/g

// https://regex101.com/r/rGf770/1
const idAttributesWithIndex = /([a-z_]+attributes_)(\d+)(_[a-z_]+)/g

export default class extends Controller {
  static targets = [
    "list",
    "template",
  ]

  addNewItem() {
    const $listEl = $(this.listTarget);
    const nextIndex = $listEl.find(".has-many-item").length;
    const newItem = this._prepareNewTemplate(nextIndex);

    $listEl.append($(newItem))
  }

  _prepareNewTemplate(formIndex) {
    const template = $(this.templateTarget.content).find(".has-many-item")[0];
    const replaceFormIndex = function(_m, pre, _existingIndex, post) {
      return pre + formIndex + post;
    }

    return template.outerHTML
      .replace(nameOrForAttributesWithIndex, replaceFormIndex)
      .replace(idAttributesWithIndex, replaceFormIndex);
  }
}
