import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "input",
    "wrapper",
  ]

  initialize() {
    const self = this;
    const $readonlyElements = $(this.wrapperTarget)
      .find("textarea.form-control[disabled]");

    this._setupEditor();
    this._setEditorContentAndCaptureReference();
    this._setupReadonlyPreview($readonlyElements);

    $(this.inputTarget).on("field-was-reset-to-original", function() {
      self._setEditorContentAndCaptureReference();
    });
  }

  _setupEditor() {
    const editorWrapper = document.createElement("div");
    this.editorEl = document.createElement("trix-editor");

    editorWrapper.appendChild(this.editorEl);
    editorWrapper.classList.add("text-editor-wrapper", "text-editor-wrapper--enabled", "flex-grow-1");

    $(this.inputTarget)
      .addClass("d-none")
      .before(editorWrapper);

    $(this.wrapperTarget)
      .find(".input-group")
      .addClass("d-flex flex-nowrap");
  }

  _setupReadonlyPreview($readonlyElements) {
    $readonlyElements.each(function(_i, el) {
      const previewWrapper = document.createElement("div");
      previewWrapper.classList.add("preview-wrapper");
      previewWrapper.innerHTML = el.value;

      $(el)
        .addClass("d-none")
        .before(previewWrapper);
    });
  }

  _setEditorContentAndCaptureReference() {
    const initialContent = this.inputTarget.value;
    const editor = this.editorEl.editor;
    const $editorEl = $(this.editorEl);

    $editorEl.off("trix-change");
    editor.loadHTML(initialContent);
    this.referenceDocument = editor.getDocument();
    $editorEl.on("trix-change", { controller: this }, this._handleEditorContentChange);
  }

  _handleEditorContentChange(event) {
    const currentDocument = event.target.editor.getDocument();
    const controller = event.data.controller;

    if(!currentDocument.isEqualTo(controller.referenceDocument)) {
      controller.inputTarget.value = event.target.value;
      controller.inputTarget.dispatchEvent(new Event("input"));
    };
  }
}
