import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "destroyField",
    "destroyButton",
    "destroyableItem",
  ]

  destroyItem(event) {
    event.preventDefault();

    const deletionIndex = this.destroyButtonTargets.indexOf(event.target);
    this.destroyFieldTargets[deletionIndex].value = "true";

    this.destroyableItemTargets[deletionIndex].classList
      .add("has-many-pending-delete");
  }
}
