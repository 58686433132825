import { Controller } from "@hotwired/stimulus"
import { select2dropdownParent } from "../utils/select2_dropdown_parent"

const cssClassDisplayNone = "d-none";

export default class extends Controller {
  static targets = [
    "originalCategoriesCard",
    "originalCategory1Select",
    "originalCategory2Select",
    "originalCategory3Select",
    "categorySelect",
    "toggleOriginalButton"
  ];

  async initialize() {
    await this._fetchCategorySelectData();
    this._initSelect2();

    this._setState();
  }

  async _fetchCategorySelectData() {
    const self = this;

    // Wait for the completion of the ajax call
    const response = await $.ajax("/categories/select_data").then((data) => {
      self.categorySelectData = data;
    }, (error) => {
      console.log("Error fetching category select data:", error);
    });
    return response;
  }

  _initSelect2() {
    const self = this;
    const $levelOneSelect = $("#category_1");
    const $levelTwoSelect = $("#category_2");
    const $levelThreeSelect = $("#product_category_id");

    $(".category-select select").select2({
      width: "100%",
      dropdownParent: select2dropdownParent(),
    });

    $levelOneSelect.on("change", function(event) {
      self.updateCategoryLevelTwoAndThree(event, self.categorySelectData)
      self._setState();
    });

    $levelTwoSelect.on("change", function(event) {
      self.updateCategoryLevelThree(event, self.categorySelectData)
      self._setState();
    });

    $levelThreeSelect.on("change", function(event) {
      self._setState();
    });

    // Trigger the change event initially to populate the other selects only if the category 2 is still empty
    if(!$levelTwoSelect.val()) {
      $('#category_1').trigger('change');
    }
  }

  _setState() {
    const originalValue = this.originalCategory3SelectTarget.value;
    const currentValue = this.categorySelectTarget.value;
    const changed = (originalValue !== "") && (originalValue !== currentValue);

    if (changed) {
      this.toggleOriginalButtonTarget.classList.remove(cssClassDisplayNone);
    } else {
      this.toggleOriginalButtonTarget.classList.add(cssClassDisplayNone);
    }
  }

  // ACTIONS

  updateCategoryLevelTwoAndThree(event, categorySelectData) {
    console.log(event);
    console.log(categorySelectData);
    const data = categorySelectData[event.target.value] || [];
    const $levelTwoSelect = $("#category_2");
    const $levelThreeSelect = $("#product_category_id");

    $levelTwoSelect
      .empty()
      .select2({ data: data })
      .val(null)
      .trigger("change");

    $levelThreeSelect
      .empty()
      .val(null)
      .trigger("change")
      .attr("disabled", "disabled");
  }

  updateCategoryLevelThree(event, categorySelectData) {
    const data = categorySelectData[event.target.value] || [];
    const $levelThreeSelect = $("#product_category_id");

    $levelThreeSelect
      .empty()
      .select2({ data: data })
      .val(null)
      .trigger("change")
      .attr("disabled", false);
  }

  toggleDisplayOriginal(event) {
    event.preventDefault();
    this.originalCategoriesCardTarget.classList.toggle(cssClassDisplayNone);
  }

  resetToOriginal() {
    const category1 = this.originalCategory1SelectTarget.value;
    const category2 = this.originalCategory2SelectTarget.value;
    const category3 = this.originalCategory3SelectTarget.value;

    $("#category_1").val(category1).trigger("change");
    $("#category_2").val(category2).trigger("change");
    $("#product_category_id").val(category3).trigger("change");

    this.originalCategoriesCardTarget.classList.add(cssClassDisplayNone);
    this._setState();
  }
}
