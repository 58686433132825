import $ from "jquery";

$(document).on("turbo:load", () => {
  initListeners();
});

function initListeners() {
  $("#product_tags").on("focusout", function () {
    let data = $(this).val().replaceAll(";", ",");
    $(this).val(data);
  });
}
