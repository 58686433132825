/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import $ from "jquery";
window.jQuery = $;
window.$ = $;

import "@hotwired/turbo-rails"

var ActiveStorage = require("activestorage");
ActiveStorage.start();

import "bootstrap/dist/js/bootstrap";
import "select2/dist/js/select2";
import "trix/dist/trix";
import "../jquery/export/product_variants";
import "../jquery/import/product_variants";
import "../jquery/product_variants";
import "../jquery/product";
import "../controllers";
import "../application/auto_logout";
import "../application/select2_theme";
import "../application/turbolinks_caching";
