import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "modalContent", "paginatedList", "paginationLink", "batchButton" ]

  initialize() {
    const modalContent = $("[data-target='tasks.modalContent']");
    if(modalContent.data("visible")) {
      this._loadModalContent(window.location.href);
      $(this.modalTarget).modal("show");
    }
  }

  showModal(e) {
    e.preventDefault();
    this._loadModalContent(this._linkUrl(e));
    $(this.modalTarget).modal("show");
  }

  hideModal() {
    $(this.modalTarget).modal("hide");
  }

  updateModal(e) {
    e.preventDefault();
    this._loadModalContent(this._linkUrl(e));
  }

  disableBatchButtons(e) {
    $(this.batchButtonTargets).addClass("disabled");
  }

  _formResultReceived(e) {
    const response = e.detail[0];
    this._updateFromHtml(response);
  }

  _linkUrl(e) {
    var target = $(e.target);
    if(e.target.tagName == "a") {
      return $(e.target).attr("href");
    }
    else {
      return $(e.target).closest("a").attr("href");
    }
  }

  _loadModalContent(url) {
    $(this.modalContentTarget).html(
      `<div class="modal-body">
        <div class="text-center text-muted">
          <i class="fas fa-circle-notch fa-spin"></i>
          Produkt wird geladen...
        </div>
      </div>`
    );
    $.get(
      url,
      (data) => this._updateFromHtml(data)
    ).fail(() => {
      $(this.modalContentTarget).html(
        `<div class="modal-body">
          <div class="text-center">
            <p class="text-danger">
              <i class="fas fa-exclamation-circle"></i>
              Fehler beim Laden des Produkts. Bitte probieren Sie es später erneut.
            </p>
            <a href="javascript:window.location.reload();">Seite neu laden</a>
          </div>
        </div>`
      );
    });
  }

  _updateFromHtml(data) {
    var doc = $(data);
    const newListHtml = doc.find("[data-target='tasks.paginatedList']").html();
    const modalContent = doc.find("[data-target='tasks.modalContent']");
    const newModalContentHtml = modalContent.html();
    $(this.paginatedListTarget).html(newListHtml);
    $(this.modalContentTarget).html(newModalContentHtml);

    if(!modalContent.data("visible")) {
      this.hideModal();
    }
  }
}
