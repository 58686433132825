import { Controller } from "@hotwired/stimulus"
import { select2dropdownParent } from "../utils/select2_dropdown_parent"

const select2options = {
  width: "100%",
  tags: true,
  allowClear: true,
};

export default class extends Controller {
  static targets = [
    "input",
  ];

  connect() {
    $(this.inputTarget).select2({
      dropdownParent: select2dropdownParent(),
      placeholder: "Merkmal eingeben um Produkt aufzuteilen",
      ...select2options,
    })
  }
}
