import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "username", "password", "submitButton" ]

  submit() {
    $(this.usernameTarget).attr("readonly", true)
    $(this.passwordTarget).attr("readonly", true)
    $(this.submitButtonTarget).attr("disabled", true).html(`
      <i class="fas fa-circle-notch fa-spin"></i>
    `)
  }
}
