import $ from "jquery";

$(document).on("turbo:load", () => {
  $(document).on("click", "button.add_fields", function (e) {
    e.preventDefault();

    let new_id = new Date().getTime();
    let type = $(this).data("fieldType");
    let regexp = new RegExp("new_" + type, "g");
    let content = $(this).data("content");

    $(this).before(content.replace(regexp, new_id));

    return false;
  });

  $(document).on("click", "button.remove_fields", function (e) {
    e.preventDefault();
    $(this).closest(".row").remove();
  });

  $(document).on("keypress", ".condition-row input", handleEnterPress);
  $(document).on("keypress", ".condition-row select", handleEnterPress);
  $(document).on("keypress", ".condition-row .reset-group-filter", handleEnterPress);

  function handleEnterPress(e) {
    if (e.which == 13) {
      $(".search-btn").trigger("click");
      return false;
    }
  }

  const entitiesIndex = $('.entities-index')

  if (entitiesIndex.length === 0) {
    return
  }

  setInterval(() => {
    $.ajax({
      url: `/product_variants/export_entities/`,
      contentType: "application/json",
      success: (data) => {
        data.export_entities.forEach((el) => {
          let td = $(`#${el.id}`)

          if (td.text().includes(el.status)) {
            return
          }

          td.text(el.status)
          if (el.status === 'done') {
            td.parent().find('.actions').prepend(`
              <i class="fas fa-save"></i>
              <a target="_blank" href="${el.file_url}">Download</a>
            `)
          }
        })
      },
    });
  }, 5000)
});
