import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "wrapper",
  ]

  initialize() {
    const self = this;

    this.$inputEl = $(this.wrapperTarget).find("input[type=text]:first");
    this.$outputEl = this.$inputEl.closest(".form-group").find(".input-notes");

    this._updateLinks();

    this.$inputEl.on("change", function() {
      self._updateLinks();
    });
  }

  _updateLinks() {
    const skus = this.$inputEl.val().split(/\s?,\s?/)
      .map(sku => sku.trim())
      .filter(sku => sku.length > 0);

    const links = skus.map(function(sku) {
      const el = document.createElement("a");
      el.href = "/products/by_sku/" + encodeURIComponent(sku);
      el.target = "_blank";
      el.text = sku;
      el.classList.add("btn-link", "btn", "btn-sm", "py-0", "px-1");
      return el;
    });

    this.$outputEl.empty();

    if(links.length === 0) {
      this.$outputEl.addClass("d-none");
    } else {
      const label = document.createElement("span");
      label.innerText = "SKUs:"
      label.classList.add("align-middle")
      this.$outputEl.append(label).append(links).removeClass("d-none");
    }
  }
}
