import { Controller } from "@hotwired/stimulus"

// https://regex101.com/r/LF3Bwh/2
const nameOrForAttributesWithIndex = /(images_attributes\]\[)(\d+)(\]\[[a-z_]+\])/g

// https://regex101.com/r/Aq7pii/1/
const idAttributesWithIndex = /(images_attributes_)(\d+)(_[a-z_]+)/g

export default class extends Controller {
  static targets = [
    "wrapper",
    "template",
  ]

  initialize() {
   const index = parseInt(this.wrapperTarget.dataset.nextImageIndex);
   this.nextImageIndex = index;
  }

  addNewImage() {
    const newItem = this._prepareNewTemplate(this.nextImageIndex);
    this.nextImageIndex++;

    $(this.wrapperTarget)
      .parent()
      .append(newItem)
      .find("input[type=file]:last")
      .trigger("click");
  }

  _prepareNewTemplate(formIndex) {
    const template = $(this.templateTarget.content).find(".template-wrapper")[0];
    const replaceFormIndex = function(_m, pre, _existingIndex, post) {
      return pre + formIndex + post;
    }

    return template.outerHTML
      .replace(nameOrForAttributesWithIndex, replaceFormIndex)
      .replace(idAttributesWithIndex, replaceFormIndex);
  }
}
