import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "enabledField",
    "destroyField",
    "coverField",
    "image",
  ]

  toggleDisableImage() {
    this._toggleHiddenField(this.enabledFieldTarget);
    this.imageTarget.classList.toggle("item-disabled");
  }

  toggleDeleteImage() {
    this._toggleHiddenField(this.destroyFieldTarget);
    this.imageTarget.classList.toggle("item-pending-delete");
  }

  setCoverImage(event) {
    const $newCoverImage = $(event.target).closest(".product-image-wrapper, .product-variant-image-wrapper");
    const $imageGroup = $newCoverImage.closest(".images-group");
    const $currentCoverImage = $imageGroup.find(".product-cover-image-wrapper, .product-variant-cover-image-wrapper");

    // Make all images for the entity not the cover
    $imageGroup.find(".image-cover-field").val("false");
    $imageGroup.find("button.set-cover-image").removeClass("btn-success");

    // Make this image the pending cover
    $(this.coverFieldTarget).val("true");

    if ($currentCoverImage.length > 0) {
      $newCoverImage.find("button.set-cover-image").addClass("d-none");
      $currentCoverImage.find("button.set-cover-image").removeClass("d-none");
      
      this._switchElementsInnerContent($newCoverImage, $currentCoverImage);
    } else {
      $newCoverImage.find("button.set-cover-image").addClass("btn-success");
    }
  }

  _toggleHiddenField(field) {
    field.value = (field.value === "true") ? "false" : "true";
  }

  _switchElementsInnerContent($el1, $el2) {
    const el1Inner = $el1.html();
    const el2Inner = $el2.html();

    $el1.html(el2Inner);
    $el2.html(el1Inner);
  }
}
