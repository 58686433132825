import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "enabledField",
    "destroyField",
    "item",
    "wrapper",
  ]

  toggleDisable() {
    this._toggleHiddenField(this.enabledFieldTarget);
    this.itemTarget.classList.toggle("item-disabled");
  }

  toggleDelete() {
    this._toggleHiddenField(this.destroyFieldTarget);
    this.itemTarget.classList.toggle("item-pending-delete");
  }

  _toggleHiddenField(field) {
    field.value = (field.value === "true") ? "false" : "true";
  }
}
