import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  static targets = ["modal", "modalContent"]

  static values = {
    group: String,
    filtering: Object,
    originUrl: String,
  };

  initialize() {
    this.loadedGroups = [];
  }

  connect() {
    const group = this.groupValue;
    if (group !== undefined) {
      $(".group-select").val(group);
    }
  }

  resetGroupFilter(e) {
    e.preventDefault()
    const select = $(e.target).parent().find('select')
    select.prop('selectedIndex', 0)
  }

  expandGroup(e) {
    const target = $(e.target);
    const table = target.parent().find("table");
    const { groupName, groupedBy } = target.data();

    if (!this.loadedGroups.includes(groupName)) {
      table.append('<div class="loader">Loading</div>');
      $.ajax({
        url: `/product_variants/export_entities/new`,
        contentType: "application/json",
        data: {
          group_name: groupName,
          grouped_by: groupedBy,
          q: this.filteringValue,
        },
        success: (data) => {
          this.loadedGroups.push(groupName);
          this._appendProductData(table, data);
          table.find(".loader").remove();
        },
      });
    }

    $(e.target).closest(".group-section").find("table").toggleClass("d-none");
  }

  toggleAllProducts(e) {
    const checked = e.target.checked;
    // TODO: two levels of checkboxes
    $("#submit-export-form")
      .find("input[type=checkbox]")
      .each(function () {
        $(this).prop("checked", checked);
      });
  }

  toggleGroupProducts(e) {
    const checked = e.target.checked;
    $(e.target)
      .parent()
      .find(".table")
      .find("input[type=checkbox]")
      .each(function () {
        $(this).prop("checked", checked);
      });
  }

  cloneConditionsRow(e) {
    e.preventDefault();

    $(".conditions button.add_fields").click();

    const initialSelects = $(e.target).parent().find("select");
    const newCondition = $(e.target).parent().parent().parent().next();

    $(initialSelects).each(function (i) {
      let select = this;
      $(newCondition).find("select").eq(i).val($(select).val());
    });
  }

  hideModal(e) {
    e.preventDefault();
    $(this.modalTarget).modal("hide");
  }

  showProductModal(e) {
    // if the clicked element is a checkbox, use the browser default
    if(e.target.type === 'checkbox') return

    e.preventDefault();
    const { url } = $(e.target).parent().data();
    const extendedUrl = `${url}?origin_url=${encodeURIComponent(this.originUrlValue)}`
    this._loadModalContent(extendedUrl);
    $(this.modalTarget).modal("show");
  }

  _loadModalContent(url) {
    $(this.modalContentTarget).html(
      `<div class="modal-body">
        <div class="text-center text-muted">
          <i class="fas fa-circle-notch fa-spin"></i>
          Produkt wird geladen...
        </div>
      </div>`
    );
    $.get(
      url,
      (data) => this._updateFromHtml(data)
    ).fail(() => {
      $(this.modalContentTarget).html(
        `<div class="modal-body">
          <div class="text-center">
            <p class="text-danger">
              <i class="fas fa-exclamation-circle"></i>
              Fehler beim Laden des Produkts. Bitte probieren Sie es später erneut.
            </p>
            <a href="javascript:window.location.reload();">Seite neu laden</a>
          </div>
        </div>`
      );
    });
  }

  _updateFromHtml(data) {
    var doc = $(data);
    $(this.modalContentTarget).html(doc);
  }

  _appendProductData(target, { product_variants }) {
    product_variants.forEach((el) => {
      target.find('tbody').append(`
        <tr class="cursor"
            data-action='click->export-product-variants#showProductModal'
            data-url='/products/${el.product_id}/modal' >
          <input type="hidden" name="variants_checked[${el.id}][]" value="false">
          <td><input class='big-checkbox' type='checkbox' checked></input></td>
          <td>${el.sku}</td>
          <td>${el.product_name}</td>
          <td>${el.name}</td>
        </tr>
      `);
    });
  }

  _tableRowHtml(product) {}

  _groupSelectHtml() {
    return `
      <div class='group-select--input row'>
        <select name="groups[]" id="groups_" class="form-control col-md-11 m-2"></select>
        <button class='btn btn-sm btn-outline-secondary col m-2' data-action='export-product-variants#removeGroup'>X</button>
      </div>
    `;
  }
}
